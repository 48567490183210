/*global jq1112:false*/
/*global $:false*/
/*global escape: true */
/*jslint browser: true*/


function addTextSizer() {
	$('#AddThisWrap').before('<div id="TextSizer" class="ClearFix"><h2>Text Sizer</h2><ul>' +
		'<li><a class="NormalFont" href="javascript:;" title="Small Font Size"></a></li>' +
		'<li><a class="LargeFont" href="javascript:;" title="Medium Font Size"></a></li>' +
		'<li><a class="X-LargeFont" href="javascript:;" title="Large Font Size"></a></li>' +
		'</ul></div>');
	$('a.NormalFont').click(function () {
		$('#Content').removeClass('textLarge').removeClass('textXLarge');
		$.cookie('text_sizer', null, {
			path: '/'
		});
	});
	$('a.LargeFont').click(function () {
		$('#Content').addClass('textLarge').removeClass('textXLarge');
		$.cookie('text_sizer', 'large', {
			path: '/'
		});
	});
	$('a.X-LargeFont').click(function () {
		$('#Content').removeClass('textLarge').addClass('textXLarge');
		$.cookie('text_sizer', 'xlarge', {
			path: '/'
		});
	});
	$('#TextSizer a').click(function () {
		$('#TextSizer a').removeClass('active');
		$(this).addClass('active');
	});
	var text_sizer_cookie = $.cookie('text_sizer');
	if (text_sizer_cookie === null) {
		$('a.NormalFont').addClass('active');
	}
	if (text_sizer_cookie === 'large') {
		$('#Content').addClass('textLarge');
		$("a.LargeFont").addClass('active');
	}
	if (text_sizer_cookie === 'xlarge') {
		$('#Content').addClass('textXLarge');
		$("a.X-LargeFont").addClass('active');
	}
}

function addMaskedEntries() {
	$(".PhoneMasked input, input.PhoneMasked").mask("999-999-9999", {
		placeholder: " "
	});
	$(".PhoneMaskedExt input, input.PhoneMaskedExt").mask("999-999-9999? x999", {
		placeholder: " "
	});
	$(".DateMasked input, input.DateMasked").mask("99/99/9999", {
		placeholder: " "
	});
	$(".SSNMasked input, input.SSNMasked").mask("999-99-9999", {
		placeholder: " "
	});

	$('.PhoneMasked small:contains("Example: 123-123-1234")').hide();
	$('.PhoneMaskedExt small:contains("Example: 123-123-1234")').hide();
	$('.SSNMasked small:contains("Example: 000-00-0000")').hide();
}



function ModServicesTabs() {
	$("#ServicesSearch ul.Tabnav").remove();
	$("#ServicesSearch div.TabContainer").attr("class", "").attr("id", "");
	$("#ServicesSearch div.Tab").attr("class", "").attr("id", "");
	$("#ServicesSearch #BrowseTab").attr("class", "").attr("id", "");
}

function FlexSlider() {
	if ($('.flexslider').length > 0) {
		$('.flexslider').flexslider({
			animation: "fade",
			slideshowSpeed: 8000,
			animationLoop: true,
			pauseOnHover: true,
			randomize: true
		});
	}
}



$(document).ready(function () {

	/* Provider search form radius #529046 make 50 miles defualt value */
	$("#DrSearch .Radius select option:first-child").attr('disabled', 'disabled').remove();  //removing Any option
	$("#DrSearch .Radius select option[value='50']").attr('selected', 'selected');  //making 50 the selected 

	// ----------- Dontaion Form
	if(('.DonationForm').length > 0) {
		//make a two deminsional array to hold the location name (the first item) and the related causes for each one
		var CausesPerLocation = [
			['MidMichigan Health', 'Area of Greatest Need', 'Compassionate Needs', 'Equipment', 'Gifts of Wellness', 'Giving Tuesday'],
			['MidMichigan Home Care', 'Butterflies in the Park', 'Circle of Care', 'Home Health Care', 'Hospice/End of Life'],
			['MidMichigan Medical Center - Alpena', 'Area of Greatest Need', 'Cancer', 'Equipment', 'Gifts of Wellness', 'Love Light Trees/Lights of Love', 'Memorial Garden'],
			['MidMichigan Medical Center - Clare', 'Area of Greatest Need', 'Breast Health', 'Cancer', 'Compassionate Needs', 'Diabetes', 'Education', 'Equipment', 'Gifts of Wellness', 'Heart', 'Rehabilitation Services', 'Love Light Trees/Lights of Love'],
			['MidMichigan Medical Center - Midland', 'Area of Greatest Need', 'Breast Health', 'Cancer', 'Compassionate Needs', 'Diabetes', 'Education', 'Equipment', 'Gifts of Wellness', 'Heart', 'Love Light Trees/Lights of Love', 'Rehabilitation Services', 'Simulation Center', 'Spiritual Care', 'Vada B. Dow Memorial Toy Fund'],
			['MidMichigan Medical Center - Gladwin', 'Area of Greatest Need', 'Breast Health', 'Cancer', 'Compassionate Needs', 'Diabetes', 'Education', 'Equipment', 'Gifts of Wellness', 'Heart', 'Love Light Trees/Lights of Love', 'Rehabilitation Services'],
			['MidMichigan Medical Center - Gratiot', 'Area of Greatest Need', 'Breast Health', 'Cancer', 'Compassionate Needs', 'Diabetes', 'Education', 'Equipment', 'Gifts of Wellness', 'Heart', 'Rehabilitation Services', 'Love Light Trees/Lights of Love', 'Simulation Center']
		];

		// adding classes to li for styling
		$(".GiveNowOrDesignate .CheckRadio ul li span").addClass('ButtonList');

		//reset the inputs on the donation form on page load. Make sure the checkboxes aren't checked or disabled
		$('.DonationForm .DesignationLocationsCauses input').attr('disabled', false).attr('checked', false);

		//either a 'Location' or a 'Cause' checkbox has been clicked
		$('.DonationForm .DesignationLocationsCauses input').click(function() {
			var isChecked = $(this).is(':checked'), //check to see whether or not the checkbox has been checked/unchecked
				locationCauseName = $(this).attr('value'); //get the name of the location or cause
			$(this).parents('li').siblings().find('span input').prop('disabled', isChecked); //disable/enable all siblings
			if(isChecked) {//an item is checked
				$(this).parent().addClass('enabled'); //enable the checked item
				$(this).parents('li').siblings().find('span.Chkbx').addClass('disabled'); //disable all siblings
			} else {
				$(this).parent().removeClass('enabled'); //an item has been unchecked
				$(this).parents('li').siblings().find('span.Chkbx').removeClass('enabled disabled'); //all siblings are neither checked or unchecked
			}

			if($(this).parents('.DesignationLocations').length > 0) {
				//we are in the Locations list
				if($(".DonationForm .DesignationCauses.checked").length > 0) {
					//a cause has been previously selected
					if(!isChecked) {
						//if we are deselecting a location (a cause is checked)...
						$(".DonationForm .DesignationLocations").removeClass('checked');
						//then we need to populate the locations based on the cause that is selected
						ShowLocationsBasedOnCauses('get cause');
					} else { //a cause is selected and we just selected a location
						$(".DonationForm .DesignationLocations").addClass('checked');
					}
				} else { //a location has been clicked on
					if(!isChecked) { //location has been unselected
						$(".DonationForm .DesignationLocations").removeClass('checked'); //unselect the location that was clicked on
						$('.DonationForm .DesignationCauses input').prop('disabled', false).parent().removeClass('enabled disabled'); //all siblings are neither checked or unchecked
					} else {
						//disable each cause since we clicked on a Location...enabling the ones we need later in a loop
						$('.DonationForm .DesignationCauses input').prop('disabled', true).parent().addClass('disabled');
						$(".DonationForm .DesignationLocations").addClass('checked');
						ShowCausesBasedOnLocation(locationCauseName);
					}
				}
			} else {
				//we are in the causes list
				if(!isChecked) { //deselecting a cause
					$(".DonationForm .DesignationCauses").removeClass('checked');
					if($('.DesignationLocations.checked').length > 0) {
						//we have unchecked a cause and a location is selected. Show the causes based on that location
						ShowCausesBasedOnLocation('get location');
					} else {
						//neither a location or a cause is selected, enable everything
						$('.DonationForm .DesignationLocations input').prop('disabled', false).parent().removeClass('enabled disabled');
					}
				} else { //selecting a cause
					$(".DonationForm .DesignationCauses").addClass('checked');
					//no location is selected
					if($('.DesignationLocations.checked').length === 0) {
						//disable each location since we clicked on a cause...enabling the ones that match the cause that is checked
						$('.DonationForm .DesignationLocations input').prop('disabled', true).parent().addClass('disabled');
						ShowLocationsBasedOnCauses(locationCauseName);
					}
				}
			}
		});

		function ShowCausesBasedOnLocation(loc) {
			if(loc === 'get location') { 
				//a cause has been unchecked. Take the location that is selected and show the available causes
				loc = $(".DonationForm .DesignationLocations input:checked").attr('value');
				$('.DonationForm .DesignationCauses input').prop('disabled', true).parent().removeClass('enabled').addClass('disabled');
			}
			//loop through the array, finding the location name then get all of the related causes, enabling them
			for(var i = 0; i < CausesPerLocation.length; i++) {
				if(loc == CausesPerLocation[i][0]) {
					for(var j = 1; j < CausesPerLocation[i].length; j++) {
						//enable each cause that is an option based on the location
						$(".DonationForm .DesignationCauses input[value='"+ CausesPerLocation[i][j] +"']").prop('disabled', false).parent().removeClass('disabled').addClass('enabled');
					}
				}
			}
		}

		function ShowLocationsBasedOnCauses(el) {
			if(el === 'get cause') {
				//a location has been unchecked. Take the cause that is selected and show the available locations
				el = $(".DonationForm .DesignationCauses input:checked").attr('value');
				$('.DonationForm .DesignationLocations input').prop('disabled', true).parent().removeClass('enabled').addClass('disabled');;
			}
			//loop through the array, finding the cause then get all of the related locations, enabling them
			for(var i = 0; i < CausesPerLocation.length; i++) {
				for(var j = 1; j < CausesPerLocation[i].length; j++) {
					if(el == CausesPerLocation[i][j]) {
						//enable each location that is an option based on the cause
						$(".DonationForm .DesignationLocations input[value='"+ CausesPerLocation[i][0] +"']").prop('disabled', false).parent().removeClass('disabled').addClass('enabled');
					}
				}
			}
		}
	}



	if( $('.DesignationLocations:empty').length ) {
		$('.BlockLabels div.DesignationLocationsCauses').hide();
	}

	// This is the script for coming from another page and having it select an option automatically
	// var referrer =  document.referrer;
	// if(referrer.indexOf('giving-tuesday') > 0) {
	// 	$('.DesignationCauses ul li input:checkbox[value="Giving Tuesday"]').click();
	// }

	// ----------- End Donation Form



	// $('#Content .Inline-Block.CampusMap a').removeClass('fancybox');

	// Add text to Map image
	$('.EnlargeMapImage a').prepend('<div class="MapText"><p>Click to enlarge</p></div>');

	/*  On the find a doctor page, there is a checkbox that will only select doctors who are accepting new patients.
	We are checking to see if this checkbox already has been checked. If so, we set a cookie to filter the results
	on the doctor search results page,  */
	if ($('[id*="acceptingNewPatients"]').length) {
		if ($('[id*="acceptingNewPatients"]:checked').length) {
			$.cookie('new_patients', 'yes', {
				path: '/'
			});
		} else {
			$.cookie('new_patients', null, {
				path: '/'
			});
		}
	}

	/* Set a cookie if the 'accepting new patients' checkbox is checked for the doctor search results page. */
	$('[id*="acceptingNewPatients"]').click(function (e) {
		if ($(this).is(':checked')) {
			$.cookie('new_patients', 'yes', {
				path: '/'
			});
		} else {
			$.cookie('new_patients', null, {
				path: '/'
			});
		}
	});

	addTextSizer();
	BasicSearch();
	FlexSlider();

	if ($('div[id*="StarsFormPage1"]')) {

		//add a class to the form for CSS styling
		$('div[id*="StarsFormPage1"]').parents('.cmspage').addClass('StarsForm');

		starsForm();
		addMoney();

		//update the first/last name to the recipient messages
		$('.firstNameMain, .lastNameMain').blur(function () {
			$('.Preview1Message .donorName, .Preview2Message .donorName').text($('.firstNameMain').val() + " " + $('.lastNameMain').val());
		});

		//populate the recipient1 name for the recipient message
		$('.recipient1Memory input, .recipient1Honor input, .recipient1AGF input').keyup(function () {
			$('.Preview1Message .Recipient').text($(this).val());
		});

		//populate the recipient1 name for the recipient message
		$('.recipient2Memory input, .recipient2Honor input, .recipient2AGF input').keyup(function () {
			$('.Preview2Message .Recipient').text($(this).val());
		});

		//Changed: Qty dropdowns for Recipient1
		$('.Source2a select').change(function () {
			var value = $(this).val();
			isNaN(value) || (value.length === 0) ? value = 0 : '';
			$('.destination2a input').val(25 * parseInt(value));
			addMoney();
		});

		//Changed: Qty dropdowns for Recipient2
		$('.recipient2Qty select').change(function () {
			var value = $(this).val();
			isNaN(value) || (value.length === 0) ? value = 0 : '';
			$('.recipient2Amount input').val(25 * parseInt(value));
			addMoney();
		});

		//change the Additional Donation value
		$('.additionalDonation select').change(function () {
			addMoney();
		});

		//we've click on Recipient 2's "Use donor address" checkbox
		$('fieldset[id*="StarsFormPage1_ctl03"] .destination2s .Chkbx input').click(function (e) {
			$('.R2FirstName').val($('.firstNameMain').val());
			$('.R2MiddleName').val($('.middleNameMain').val());
			$('.R2LastName').val($('.lastNameMain').val());
			$('.R2Addr1').val($('.addr1Main').val());
			$('.R2Addr2').val($('.addr2Main').val());
			$('.R2City').val($('.cityMain').val());
			$('.R2State').val($('.stateMain').val());
			$('.R2Zip').val($('.zipMain').val());
		});
	}

	function starsForm() {
		var quantity1 = 0,
			quantity2 = 0,
			subTotalQty = 0,
			subTotalAmt = 0,
			addDonation = 0,
			totalAmt = 0,
			donationType1 = '',
			donationType2 = '',
			NameMain = '',
			Name1 = '',
			Name2 = '',
			recip1Name = '',
			recip2Name = '',
			giftMsg1 = '',
			giftMsg2 = '';

		//make these inputs read-only
		$('.destination2a input, .recipient2Amount input, .SubtotalAmount input, .Qty input, .totalQty input, .totalAmount input').attr('disabled', 'disabled');
		$('div[id*="AmountHide"] input').attr('disabled', false); //the inputs that are disabled are not showing up properly on page 2. Creating duplicate inputs that are hidden to submit

		NameMain = GetName($('.source1a input'));

		quantity1 = $('.Source2a select option:selected').val(); //recipient 1 quantity dropdown
		quantity2 = $('.recipient2Qty select option:selected').val(); //recipient 2 quantity dropdown

		isNaN(quantity1) || (quantity1.length === 0) ? quantity1 = 0 : '';
		isNaN(quantity2) || (quantity2.length === 0) ? quantity2 = 0 : '';

		addClasses();

		$('.destination2a input').val((quantity1 * 25).toFixed(2));
		$('.recipient2Amount input').val((quantity2 * 25).toFixed(2));

		addMoney();

		/*subTotalQty = parseInt(quantity1) + parseInt(quantity2); alert(subTotalQty);
        $('.Qty input').val(subTotalQty);
        $('.totalQty input').val(subTotalQty);
        subTotalAmt = (quantity1 * 25) + (quantity2 * 25);
        $('.SubtotalAmount input').val(subTotalAmt.toFixed(2));
        addDonation = $('.additionalDonation input').val();
        totalAmt = subTotalAmt;
        $('.totalAmount input').val(parseInt(subTotalAmt) + parseInt(addDonation).toFixed(2));
		$('.totalAmountHidden input').val(parseInt(subTotalAmt) + parseInt(addDonation).toFixed(2));*/

		//Recipient 1
		Name1 = GetName($('.destination1a input'));

		giftMsg1 = $('.recipient1Gift input:checked').siblings('label').text();
		donationType1 = $('.recipient1Gift input:checked').val();

		if (giftMsg1 === "In memory of") {
			recip1Name = $('.recipient1Memory input').val();
		} else if (giftMsg1 === "In honor of") {
			recip1Name = $('.recipient1Honor input').val();
		} else {
			recip1Name = $('.recipient1AGF input').val();
		}

		//Recipient 2
		Name2 = GetName($('.recipient2Name input'));

		giftMsg2 = $('.recipient2Gift input:checked').siblings('label').text();
		donationType2 = $('.recipient2Gift input:checked').val();

		if (giftMsg2 === "In memory of") {
			recip2Name = $('.recipient2Memory input').val();
		} else if (giftMsg2 === "In honor of") {
			recip2Name = $('.recipient2Honor input').val();
		} else {
			recip2Name = $('.recipient2AGF input').val();
		}


		$('.Preview1Message .donorName').text(NameMain);
		$('.Preview1Message .donationType').text(donationType1);
		$('.Preview1Message .Recipient').text(recip1Name);

		$('.Preview2Message .donorName').text(NameMain);
		$('.Preview2Message .donationType').text(donationType2);
		$('.Preview2Message .Recipient').text(recip2Name);

		function addClasses() {
			//Add Classes to Donor Fields
			$('.source1a input').each(function () {
				if ($(this).attr('id').indexOf('FirstName') > -1) {
					$(this).addClass('firstNameMain');
				} else if ($(this).attr('id').indexOf('MiddleName') > -1) {
					$(this).addClass('middleNameMain');
				} else if ($(this).attr('id').indexOf('LastName') > -1) {
					$(this).addClass('lastNameMain');
				}
			});
			$('.source1b input').each(function () {
				if ($(this).attr('id').indexOf('HomeAddressLine1') > -1) {
					$(this).addClass('addr1Main');
				} else if ($(this).attr('id').indexOf('HomeAddressLine2') > -1) {
					$(this).addClass('addr2Main');
				}
			});
			$('.source1c input').each(function () {
				if ($(this).attr('id').indexOf('HomeCity') > -1) {
					$(this).addClass('cityMain');
				} else if ($(this).attr('id').indexOf('HomeState') > -1) {
					$(this).addClass('stateMain');
				} else if ($(this).attr('id').indexOf('HomePostalCode') > -1) {
					$(this).addClass('zipMain');
				}
			});

			//Add Classes to Recipient2 Fields
			$('.recipient2name input').each(function () {
				if ($(this).attr('id').indexOf('Recipient2FirstName') > -1) {
					$(this).addClass('R2FirstName');
				} else if ($(this).attr('id').indexOf('Recipient2MiddleName') > -1) {
					$(this).addClass('R2MiddleName');
				} else if ($(this).attr('id').indexOf('Recipient2LastName') > -1) {
					$(this).addClass('R2LastName');
				}
			});

			$('.destination2b input').each(function () {
				if ($(this).attr('id').indexOf('Recipient2AddressesHomeAddressLine1') > -1) {
					$(this).addClass('R2Addr1');
				} else if ($(this).attr('id').indexOf('Recipient2AddressesHomeAddressLine2') > -1) {
					$(this).addClass('R2Addr2');
				}
			});

			$('.destination2c input').each(function () {
				if ($(this).attr('id').indexOf('Recipient2AddressesHomeCity') > -1) {
					$(this).addClass('R2City');
				} else if ($(this).attr('id').indexOf('Recipient2AddressesHomeState') > -1) {
					$(this).addClass('R2State');
				} else if ($(this).attr('id').indexOf('Recipient2AddressesHomePostalCode') > -1) {
					$(this).addClass('R2Zip');
				}
			});
		}

		function GetName(selector) {
			var first = "",
				last = "";
			$(selector).each(function () {
				if ($(this).attr('id').indexOf('FirstName') > -1) {
					first = $(this).val();
				} else if ($(this).attr('id').indexOf('LastName') > -1) {
					last = $(this).val();
				}
			});
			return first + ' ' + last;
		}
	}

	function addMoney() {
		var Qty1 = parseInt($('.Source2a select option:selected').text());
		var Qty2 = parseInt($('.recipient2Qty select option:selected').text());

		isNaN(Qty1) || (Qty1.length === 0) ? Qty1 = 0 : '';
		isNaN(Qty2) || (Qty2.length === 0) ? Qty2 = 0 : '';

		var Qty = '';
		var Donation = 0;

		Qty = Qty1 + Qty2;

		$('.Qty input').val(Qty);
		$('.SubtotalAmount input').val(((Qty) * 25).toFixed(2));
		$('.totalQty input').val(Qty);

		Donation = $('.additionalDonation select option:selected').val();

		if (Donation > 0) {
			$('.totalAmount input').val((parseInt((Qty) * 25) + parseInt(Donation)).toFixed(2));
			$('.totalAmountHidden input').val((parseInt((Qty) * 25) + parseInt(Donation)).toFixed(2));
		} else {
			$('.totalAmount input').val(parseInt((Qty) * 25).toFixed(2));
			$('.totalAmountHidden input').val(parseInt((Qty) * 25).toFixed(2));
		}
	}


	// ----------- TEMPLATE EXTRAS ------------------//
	addMaskedEntries(); // Needed for Event & Form inputs that are masked. -dab
	ModServicesTabs();

	//changing text on Place Order "Register" button on shopping cart form
	$('#ShoppingCart input.PayNow').attr('value', 'Register');

	// Hide Email option on Cheer Cards per Bug 21165
	$("div.CheerCards h3:contains('How should we deliver this card?')").hide().next('fieldset').addClass('CardDeliveryOptions');
	$('.CardDeliveryOptions .CheckRadio').hide().prev('div').hide();
	$('.CardDeliveryOptions>div:last').hide();
	$('.CardDeliveryOptions input:checkbox:last').attr('checked', true);
	$("#Content ul.MessageWarning li:contains('Please select a location, or send as an email message only.')").text("Please select a location.");
	$("div.CheerCards span:contains('Please select a location, or send as an email message only.')").text("Please select a location.");

	// Cheer Cards changes bug 24571
	$("div.CheerCards .CheerCatList p.View").replaceWith("<h2 class='View'>Cards by Category</h2>");
	$("div.CheerCards .CheerCatDetail a.Nav:contains('All Cards')").text("All Categories");
	$('.CheerPersonalize .Column label:contains("Your Phone")').closest('.Column').parent('div').addClass('CardSenderEmailPhone');
	$('.CardSenderEmailPhone>div').not(':first').hide();

	// Cheer cards
	$('.CheerCatDetail .Nav').html('Change Category').insertAfter('.CheerCardGroup');

	// Find a Doctor -- Make the Address/Phone/Map tab the default tab
	// Display google map -- otherwise map only pulls in for the locations tab
	$('#DrDetail .Tabnav li:nth-child(2) a').trigger('click');
	// Remove class Tabactive from the first tab
	$('#DrDetail .Tabnav li:first-child').removeClass('Tabactive');
	// Add class Tabactive to 2nd tab
	$('#DrDetail .Tabnav li:nth-child(2)').addClass('Tabactive');

	//Change order of tabs
	// Move office location li above profile li
	$('#DrDetail .Tabnav li:first-child').insertAfter('#DrDetail .Tabnav li:nth-child(2)');
	// Move office location div above profile div
	$('div #VsMasterPage_ctl00_MainContentPlaceHolder_MainContent_ProfileInfo_OfficeTab').insertBefore('div #VsMasterPage_ctl00_MainContentPlaceHolder_MainContent_ProfileInfo_ProfileTab');
	// Move office location div above profile div on the mobile version
	$('div #VsMasterPage_MainContent_ProfileInfo_OfficeTab').insertBefore('div #VsMasterPage_MainContent_ProfileInfo_ProfileTab');

	//if the profile tab has the Biography section, creat a new tab and display it there
	if ($('#DrDetail .TabContainer div[id*="ProfileTab"] .Biography').length > 0) { //if the Biography section exists in the Profile tab
		var Biography = $('#DrDetail .TabContainer div[id*="ProfileTab"] .Biography');
		$('#DrDetail ul.Tabnav li a').each(function () {
			if ($(this).text() === "Profile") {
				$(this).parent('li').after('<li><a href="#Biography">Biography</a></li>'); //build the Biography tab button
			}
		});
		$('#DrDetail .TabContainer div[id*="ProfileTab"]').after('<div class="Bio Tab Tabhide ClearFix"></div>'); //build the Biography tab container
		$('#DrDetail .TabContainer .Bio').html(Biography);
	}

	//Rearranging Doctor Profile
	// add stage and live fact ids as follows: $('.DEV,.STAGE,.LIVE')
	var providerTypeDivs = $('.facetcc7b7ba418d74860a288a00530a6bad7'),
		boardCertDivs = $('.facet356d7dd066a0490c8e19d7c89c9bcb7c'),
		languagesDivs = $('.facet418c8b835b6f468aa7680710fa6f2d03'),
		proceduresDivs = $('.facet73069a6b4587488aae0f0e43773730d0'),
		insuranceDivs = $('.facetd6b2c222d5a443dcbe7e4f8023d102fe');

	var gender = ($('#DrDetail .Gender').size() > 0) ? '<div><h3 >Gender</h3><ul><li>' + $('#DrDetail .Gender dd').text() + '</li></ul></div>' : '',
		year = ($('#DrDetail .Experience').size() > 0) ? '<div class="Experience"><h3 class="Heading">Year Began Practicing</h3><ul><li>' + $('#DrDetail .Experience dd').text() + '</li></ul></div>' : '',
		boardCert = (boardCertDivs.size() > 0) ? '<div class="BoardCertified"><h3 class="Heading">Board Certified</h3><ul>' + boardCertDivs.find('ul').html() + '</ul></div>' : '',
		practice = ($('#DrDetail .Practice').size() > 0) ? '<div class="Practice">' + $('#DrDetail .Practice').html() + '</div>' : '',
		languages = (languagesDivs.size() > 0) ? '<div class="Languages"><h3 class="Heading">Languages</h3><ul>' + languagesDivs.find('ul').html() + '</ul></div>' : '',
		education = ($('#DrDetail .EducationAndTraining').size() > 0) ? '<div class="EducationAndTraining">' + $('#DrDetail .EducationAndTraining').html() + '</div>' : '',
		procedures = (proceduresDivs.size() > 0) ? '<div class="SpecialProcedures"><h3 class="Heading">Special Procedures</h3><ul>' + proceduresDivs.find('ul').html() + '</ul></div>' : '',
		comments = ($('#DrDetail .Comments').size() > 0) ? '<div class="Comments">' + $('#DrDetail .Comments').html() + '</div>' : '',
		memberships = ($('#DrDetail .ProfessionalMemberships').size() > 0) ? '<div class="ProfessionalMemberships">' + $('#DrDetail .ProfessionalMemberships').html() + '</div>' : '',
		philosophy = ($('#DrDetail .Philosophy').size() > 0) ? '<div class="Philosophy">' + $('#DrDetail .Philosophy').html() + '</div>' : '',
		insurance = (insuranceDivs.size() > 0) ? '<div class="ParticipatingInsurance"><h3 class="Heading">Participating Insurances*</h3><ul>' + insuranceDivs.find('ul').html() + '</ul></div>' : '',
		disclaimer = ($('#DrDetail .InsuranceDisclaimer').size() > 0) ? '<div class="InsuranceDisclaimer">' + $('#DrDetail .InsuranceDisclaimer').html() + '</div>' : '';

	// move provider type to upper section
	if (providerTypeDivs.size() > 0) {
		$('#DrDetail .Designations .JobTitle').after('<div class="ProviderType"><p><strong>Provider Type: </strong><span>' + providerTypeDivs.find('ul').text() + '</span></p></div>');
	}

	// remove Manually-assigned provider profile sections
	providerTypeDivs.add(boardCertDivs).add(languagesDivs).add(proceduresDivs).add(insuranceDivs).add($('#DrDetail .Gender')).add($('#DrDetail .Experience')).add($('#DrDetail .Practice')).add($('#DrDetail .EducationAndTraining')).add($('#DrDetail .Comments')).add($('#DrDetail .ProfessionalMemberships')).add($('#DrDetail .Philosophy')).add($('#DrDetail .InsuranceDisclaimer')).remove();

	// add the Manually-assigned provider profile sections in the right order
	$('#DrDetail div[id*="ProfileData"]').prepend(gender + year + boardCert + practice + languages + education + procedures + comments + memberships + philosophy + insurance + disclaimer);

	// move facebook iframe under h1
	$('#DrDetail .Designations h1').after($('#DrDetail .Designations iframe').remove());
	//$('#DrDetail .Designations iframe').after('<hr/>');

	//add recommedations box to live button on provider details
	var $fbIframe = $('iframe[src*="facebook.com/plugins/like.php"]');
	if ($fbIframe.size() > 0) {
		var fbsrc = $fbIframe.attr('src').replace('&height=40', '').replace('&show_faces=false', '');
		$fbIframe.css('height', '').attr('src', fbsrc + '&appID=242616895769339&show_faces=true&send=false');
	}

	// ----------- HACK LIST START ------------------//


	//AJAX for Event Results - add City and State
	$('.EventResults dd').each(function () {
		$(this).append('<p class="CityAndState"></p>');
		var EventLink = $(this).find('h5 a').attr('href');
		var EventCityState = $(this).find('.CityAndState');
		$.ajax({
			url: EventLink,
			type: 'GET',
			success: function (data) {
				var CityState = $(data).find('.CalendarEventDetailHeader .EventLocationCity').html();
				EventCityState.html(CityState);
			}
		});
	}); /* --- CLOSING AJAX --- */


	$('a#ViewMore').click(function () {
		$('#ViewMoreContent').slideToggle('Open');
		$('a#ViewMore').toggleClass('Active');
		return false;
	});

	$('a#QuickLinks').click(function () {
		$('#QuickLinksMenu').slideToggle('Open');
		$('a#QuickLinks').toggleClass('Active');
		return false;
	});

	$("a#left-menu").click(function () {
		$(".MobileNavMenu li.First").toggleClass("Active");
		return false;
	});

	$("a#right-menu").click(function () {
		$(".MobileNavMenu li.Last").toggleClass("Active");
		return false;
	});

	$('#MainNav ul li:nth-child(2)').addClass('Second');
	$('#MainNav ul li:nth-child(3)').addClass('Third');
	$('#MainNav ul li:nth-child(4)').addClass('Fourth');

	//Hiding .slides li to make the flex slider load faster when animation=fade,
	//doing this through jquery so when java is turned off the slides will appear
	$('#Home #Banner .flexslider ul.slides li').addClass('HideSlide');

	if ($(window).width() > 800) {
		if ($('.fancybox').length) {
			$(".fancybox").fancybox({
				'autoscale': true,
				'overlayColor': '#1f1f1f',
			});
		}
	} else {
		// open in a new window
	}

	//Open YouTube videos in fancybox
	$("a.YouTubeVideo").fancybox({
		'transitionIn': 'elastic',
		'transitionOut': 'elastic',
		'autoscale': true,
		'overlayColor': '#1f1f1f',
		'titleShow': true,
		'titlePosition': 'outside',
		'type': 'iframe'
	});
    
    /* Add VideoContainer around iframed YouTube videos
    ---------------------------------------------------------------- */
	$('iframe[src*="youtube.com"]').each(function () {
		$(this).wrap('<div class="VideoContainer" />');
	});

	/*  FancyBox for Various Scenarios
    ----------------------------------------------------------------- */
	$("a.YouTubeVideo").fancybox({type: 'iframe' });
    $("a.FancyImage").fancybox();
	$("a.FancyYouTube").fancybox({
        type: 'iframe',
        height: 720,
        width: 1280,
        autoDimensions: false,
		onComplete : function () {
			$("#fancybox-frame").attr("allowfullscreen", "allowfullscreen");
		}
    });
	$("a.Fancyiframe").fancybox({ type: 'iframe' });

	$('<span class="YouTubeVideoButton" />').insertAfter('a.YouTubeVideo img');
    

	// Locations Module -  Move Phone number into it's own div
	$('.LocationsList > ul > li').each(function () {
		$(this).addClass('ClearFix');
		var Phone = $(this).find('.Phone').html();
		if (Phone === null || Phone === undefined || Phone === "undefined") {
			$(this).find('.Headline').after('<div class="MovedPhone"></div>');
		} else {
			$(this).find('.Headline').after('<div class="MovedPhone">' + Phone + '</div>');
		} 
		$(this).find('.Phone').remove();
		$(this).find('.MajorDetails').after($(this).find('.MinorDetails'));
		$(this).find('.Headline,.MovedPhone').wrapAll('<div class="NamePhone" />');
		$(this).find('.MajorDetails,.MinorDetails').wrapAll('<div class="Details" />');
	});

	// For Home Template: if any div.cmspage is empty, let's remove it -- avoids padding on empty pages.
	//need to be logged out to have empty div removed
	$('#Home div.cmspage').each(function (i, el) {
		// Remove white space
		var html = $(el).html().replace(/\s+/, '');
		// Check if element is :empty or length of html is zero
		if ($(el).is(':empty') || html.length === 0) {
			$(el).remove();
		}
	});

	// Now find the the locations with no phone # displaying null and replace it with blank text
	$('.MovedPhone').each(function () {
		var text = $(this).text();
		$(this).text(text.replace('null', ''));
	});

	//Adding classes to table cells in accordion so the date icons can be hidden at mobile sizes
	$('table.CalendarEventDetailHeader tr td:nth-child(1)').addClass('First');
	$('table.CalendarEventDetailHeader tr td:nth-child(2)').addClass('Second');
	$('table.CalendarEventDetailHeader tr td:nth-child(3)').addClass('Third');

	$('.CheerPersonalize .BlockLabels div:nth-child(3)').addClass('Third');
	$('.CheerPersonalize .Column:nth-child(2)').addClass('Second');
	$('.CheerPersonalize .Column:nth-child(3)').addClass('Third');
	$('.CheerPersonalize .Column:nth-child(4)').addClass('Fourth');


	// $('.Paging').each(function () {
	// 	var BackNext = $(this).find('.BackNext');
	// 	$(this).find('td:first').append('<div class="BackNext">' + BackNext.html() + '</div>');
	// 	$(this).find('.Page > span').remove();
	// 	$(BackNext).remove();
	// 	$(this).find('.BackNext span').removeClass('FloatRight');
	// });

	//Remove In This Section button if no proximity nav is present.
	if ($('#ProximityNav ul').length <= 0) {
		$('#ViewMoreWrapper').hide();
	}

	setTimeout(function () {
		// Locations Locate on Map Link icons
		$('.MapContainer').each(function () {
			$('.GoogleLocateOnMapLink a').prepend('<span></span>');
			$('.GoogleLocateOnMapLink a span').each(function (i) {
				$(this).html(String.fromCharCode(97 + i) + "");
			});

		});
	}, 1000);

	//make the Promotional Designations logo clickable on the profile detail page
	$('#DrDetail .QuickInfo .Designations ul.Promotions li strong').contents().unwrap().wrap('<a href="https://www.midmichigan.org/mymidmichigan/">');

	// add Clearfix to site search - dr results
	$('#SiteSearchContainer #DrResults').addClass('ClearFix');

	/* Mobile Navigation
	----------------------------------------------------------------- */
	$('.CustomToggles').each(function () {
		// create variables to use for the triggers and payloads
		var $CT = $(this),
			$triggers = $CT.find('.Triggers>li'),
			$payloads = $CT.find('.Payloads>li');

		// loop thru the triggers - using "i" as the counter, you can associate the tigger with the payload
		$triggers.each(function (i, trigger) {

			// clicking a trigger will slide up all payloads and will only slide down
			// if the associated payload wasn't the one you triggered
			$(trigger).click(function () {
				$triggers.removeClass('Active');

				if ($payloads.eq(i).is(':hidden')) {
					$payloads.slideUp();
					// slide down the associated payload
					// .slideDown(1000) is 1 second
					// .slideDown(1000, function(){ do.something })
					// do.something happens after the slideDown command is finished.
					// zIndexTop is a class to make sure that the one you
					// clicked on is always sliding over the others.
					$payloads.eq(i).addClass('zIndexTop').slideDown(300, function () {
						$(this).removeClass('zIndexTop');
						$triggers.eq(i).addClass('Active');

						// Equal Heights on wait time boxes
						// Need to run on click or some of the text gets covered up
						// equalHeights function is located in modern.js so this will always give a lint error
						$("ul.WaitTimes li").css('height', 'auto');
						equalHeight($("ul.WaitTimes li"));
					});
				} else {
					$payloads.slideUp();
				}
			});
		});
	});

	$('#PageContent .MapContainer .AcceptingPatients').remove();




	// ----------- HACK LIST END ------------------//
	if ($('#MainNavWrap').length > 0) {
		var stickyNavTop = $('#MainNavWrap').offset().top;
		var stickyNav = function () {
			var scrollTop = $(window).scrollTop();
			if (scrollTop > stickyNavTop) {
				$('#MainNavWrap').addClass('Fixed');
			} else {
				$('#MainNavWrap').removeClass('Fixed');
			}
		};
		stickyNav();
		$(window).scroll(function () {
			stickyNav();
		});
	}
	/* Doctor Results Pages */
    if($('#DrResults').length) {
        // Click Function "Filter Search Results"
		$('#ProviderAdvancedSearch').removeClass('ProviderAdvancedSearch');
		$('#ProviderAdvancedSearch').addClass('DrResultsPage');
        $(document).on('click', '#ProviderAdvancedSearch > h2', function(){
            if($('#ProviderAdvancedSearch fieldset').css('display') !== 'none'){
                $('#ProviderAdvancedSearch fieldset').slideUp(500);
                $('#ProviderAdvancedSearch img').removeClass('active');
            }
            else{
                $('#ProviderAdvancedSearch fieldset').slideDown(500);
                $('#ProviderAdvancedSearch img').addClass('active');
            }
        });

		// Add advanced search h2 after basic search field
    	$('#ProviderAdvancedSearch').prepend('<h2>Filter Search Results</h2><div class="ArrowImage"><img src="/imgs/icons/dropDown.svg" /></div>');
	}
	if($('#DrSearch').length) {
		$('#ProviderAdvancedSearch').prepend('<h2>Advanced Search</h2>');
	}
	
	
	/* Careers */
	$('#CareersLeftCol #ProxNavWrap .cmspanel').insertAfter('#CareersLeftCol #ProxNavWrap #ProximityNav h2');
	
	//  Location Detail page -- Change Main to Phone on 
	var thisphone = $('#LocationsDetail .ContactCol2 p:contains("Main")').html();
	if (thisphone !== undefined && thisphone !== null) {
		$('#LocationsDetail .ContactCol2 p:contains("Main")').html(thisphone.replace('Main', 'Phone'));	
	}
	
	//  Location Detail page -- Move "Building" name above address line 1
	var building = $('#LocationsDetail .facetadb818dcf2474fa59e410ffc7a12c037 ul, #LocationsDetail .facetede7a82d980a4c9cbe320bbfe7cd1518 ul, #LocationsDetail .facet98c1df4a55b940c4a5cdde9c53d871a0 ul').html();
		if (building !== undefined && building !== null) {
		building = building.replace('<li>','<strong>').replace('</li>','</strong>');
		building = '<br>' + building;
		$(building).insertAfter('#VsMasterPage_MainContent_LocationAddress strong');	
	}
	$('#LocationsDetail .facetadb818dcf2474fa59e410ffc7a12c037, #LocationsDetail .facetede7a82d980a4c9cbe320bbfe7cd1518, #LocationsDetail .facet98c1df4a55b940c4a5cdde9c53d871a0').remove();
	
	// Locations Search Results
	/* Use Ajax to locate and pull in the Building Name */
	$('#LocationsSearch .LocationsList li').each(function (i, element) {
		var pageURL = $(element).find('a.Name').attr('href'); // find the location of the anchor

		$.ajax({
			url: pageURL,
			success: function (contents) { // go to that page

				if ($(contents).find('h3:contains("Building")')[0]) { // make sure there is one on that page

					var building = $(contents).find('h3:contains("Building")').next('ul').text(); // grab the content

					if (building.length) {
						$(element).find('.Headline').append('<br><span class="Building">' + building + '</span>');
					}
				}
			}
		});
	});
	
	// Privder Profile
	/* Use Ajax to locate and pull in the Building Name */
	$('#DrDetail .ProviderLocationName').each(function (i, element) {
		var pageURL = $(element).find('a').attr('href'); // find the location of the anchor

		$.ajax({
			url: pageURL,
			success: function (contents) { // go to that page

				if ($(contents).find('h3:contains("Building")')[0]) { // make sure there is one on that page

					var building = $(contents).find('h3:contains("Building")').next('ul').text(); // grab the content

					if (building.length) {
						$(element).append('<br><span class="Building">' + building + '</span>');
					}
				}
			}
		});
	});

	// VS 29 Fixes

    if (window.location.href.indexOf("/facility/") > -1) {
	    if (window.location.href.indexOf("/facility/search-results/") > -1) {
	    	$('.MovedPhone').css('display','none');
		}
		else{
			$('#LocationsSearch .SearchBox, #LocationsSearch .Paging, #LocationsSearch .LocationsList').css('display','none');
		}
	}

});

// Provder Results
/* Use Ajax to locate and pull in the Building Name */
function locationBuilding() {
	$('#DrResults .ProviderLocationName').each(function (i, element) {
		var pageURL = $(element).find('a').attr('href'); // find the location of the anchor

		$.ajax({
			url: pageURL,
			success: function (contents) { // go to that page

				if ($(contents).find('h3:contains("Building")')[0]) { // make sure there is one on that page

					var building = $(contents).find('h3:contains("Building")').next('ul').text(),
                        buildingElementLength = $(element).find("span.Building").length;
                    
                   
					if (building.length > 0 && buildingElementLength === 0) {
                         
						$(element).append('<br><span class="Building">' + building + '</span>');
					}
				}
			}
		});
	});
}

function BasicSearch() {
	// This markup is added to the panel
	// Markup to look like this:
	//    <div class="basicSearch">
	//        <input class="theKeyword" name="keyword" type="text" />
	//        <input class="theSearchPage" type="hidden" value="/site-search" />
	//        <input class="thisInputText" type="hidden" value="i am looking for" />
	//        <input type="hidden" class="querystr" value="term" >
	//        <input type="hidden" class="sorting" value="0" >
	//        <input type="hidden" class="errorText" value="Please enter in your search criteria." >
	//        <input class="Button basicSearchButton" type="button" value="Search" />
	//    </div>
	// NOTE: querystr and sorting inputs are OPTIONAL - if they don't exist, they default to "keyword" and "7" respectively

	$('.basicSearch').each(function () {
		var $BS = $(this),
			defaultText = $BS.find('input.theKeyword').val(),
			$input = $BS.find('input.theKeyword'),
			searchPage = $.url.attr('protocol') + '://' + $.url.attr('host') + $BS.find('input.theSearchPage').val() + '?',
			querystr = $BS.find('input.querystr').val() || '',
			querystr = (querystr.length) ? querystr + '=' : 'keyword=',
			sorting = $BS.find('input.sorting').val() || '',
			sorting = sorting !== '0' ? (sorting.length ? 'sort=' + sorting + '&' : 'sort=7&') : '',
			errorText = $BS.find('input.errorText').val() || '',
			errorText = (errorText.length) ? errorText : 'Please enter in your search criteria.';
		if (defaultText.length) {
			$input.val(defaultText)
		};
		// keyboard input bindings
		$input
			.bind('focus', function () {
				if ($(this).val() === defaultText) {
					$(this).val('');
				}
			})
			.bind('blur', function () {
				if ($(this).val() === '') {
					$(this).val(defaultText);
				}
			})
			.bind('keypress', function (e) {
				$BS.find('.error').slideUp().remove();
				if (e.keyCode == 13) {
					e.preventDefault(); // dont submit the ASP.Net form
					if (defaultText.length === 0) {
						defaultText = '';
					}
					var kword = $(this).val();
					if (kword.length && kword !== defaultText) {
						window.location = (searchPage + sorting + querystr + kword);
					} else {
						showBSError($input);
					}
				}
			});
		// clicking on the search button
		$BS.find('.basicSearchButton').click(function () {
			if (defaultText.length === 0) {
				defaultText = '';
			}
			var kword = $input.val();
			if (kword.length && kword !== defaultText) {
				window.location = (searchPage + sorting + querystr + kword);
			} else {
				showBSError($input);
			}
		});
		// show error message
		function showBSError(input) {
			if ($BS.find('.error').size() === 0) {
				input.before('<div style="color:red" class="error">' + errorText + '</div>');
			}
		}
	});
}

// ----------- AFTER PAGE LOAD ------------------//
$(window).bind("load", function () {
	'use strict';

	//$('a#evalia_link').addClass('evalia_link')

	//Third party health assessments
	$(".Desktop a#evalia_link").fancybox({
		'type': "iframe",
		'width': 950,
		'height': 600,
		'padding': 0
	});

	$(".Desktop a#evalia_link2").fancybox({
		'type': "iframe",
		'width': 950,
		'height': 600,
		'padding': 0
	});

	$(".Desktop a#evalia_link3").fancybox({
		'type': "iframe",
		'width': 950,
		'height': 600,
		'padding': 0
	});

	$(".Desktop a#evalia_link4").fancybox({
		'type': "iframe",
		'width': 950,
		'height': 600,
		'padding': 0
	});

	$(".Desktop a#evalia_link5").fancybox({
		'type': "iframe",
		'width': 950,
		'height': 600,
		'padding': 0
	});

	providerVideo();
	locationBuilding();

	// Dr Infinite Scroll
	$(function () {
		if ($('#DrResults .SortContainer').size() > 0) {
			$.getScript('/scripts/jquery-infinite-scroll.js', function () {

				var totalRecords = $('#DrResults .SortContainer>span').html().match(/\d+$/), //find the last group of digits
					page = 1,
					loadedPage = 0;
				var count = 10;

				if (count > totalRecords) {
					//show the correct total number of records if less than 10
					count = totalRecords;
				}
				$('#DrResults .SortContainer>span').addClass('ResultsCount');
				updateCounter(count, totalRecords);

				$('#DrResults .SortContainer ' + loadedPage).clone().insertAfter('#DrResults .DrListContainer');

				jQuery.ias({
					container: '.DrList',
					item: '.Profile',
					pagination: '.Paging',
					next: '.Paging .Next',
					loader: '<img src="/imgs/icons/spinCircle32.gif"/>',
					noneleft: '',
					triggerPageThreshold: 9999,
					thresholdMargin: -250,
					/*onPageChange: function (pageNum, pageUrl, scrollOffset) {
					    // this occurs during the scroll process even when you scroll back up the page
					},*/
					onLoadItems: function () {
						loadedPage += 1;

						//If some sort of action needs to happen on the new items that are brought in, do it here with a small timeout
						setTimeout(function () {
							providerVideo(); //call a function to apply to the newly added rows
							locationBuilding(); //call a function to bring in building text in dr results
						}, 100);

						if (((page + loadedPage) * 10) <= totalRecords) {
							$('#DrResults .SortContainer .HowMany').text((page + loadedPage) * 10);
							/*var loadedPageString = loadedPage.toString();
							if (!(typeof _gaq === "undefined")) { //google analytics
							    _gaq.push(['_trackEvent', 'Infinite Scroll', 'Scroll', 'Page ' + loadedPageString]);
							}*/

						} else {
							//show the correct results that are displaying if not an exact multiple of 10
							updateCounter(totalRecords, totalRecords);
						}
					}
				});
			});
		}
	});

	$('.DrListContainer').addClass('ClearFix');


	$('#DrResults .SortContainer').each(function () {
		if (!$.trim($('#DrResults .SortContainer').html()).length) {
			$(this).remove();
		}
	});

	function updateCounter(count, totalRecords) {
		$('#DrResults .SortContainer span.ResultsCount').html('<span class="Showing">Showing 1-</span><span class="HowMany">' + count + '</span><span class="OfText">&nbsp;of&nbsp;</span><span class="TotalCount">' + totalRecords + '</span>');
	}
	// end Dr Infinite Scroll

	if ($('#DrResults').size() > 0) {
		$("#DrResults").searchIdPrintResults({
			topLink: '#DrResults #ProviderBrowse > .SortContainer'
		});
	}
});

function desktopProviderSearch(){
    if($('body.Desktop #ProviderAdvancedSearch, body.Tablet #ProviderAdvancedSearch').length){
        var filterSearch = $('#ProviderAdvancedSearch');
        $(filterSearch).remove();
        $('#ProximityNav').append(filterSearch);
        $('#ProviderAdvancedSearch').show();
        $('#ProviderAdvancedSearch fieldset').show();
        $('#ProviderAdvancedSearch div.ArrowImage img').addClass('active');
    }
}

function mobileProviderSearch(){
	var filterSearch = $('#ProviderAdvancedSearch');
    $(filterSearch).remove();
    $(filterSearch).insertAfter('#PageContent .DoctorBasicNewSearchLink');
    $('#ProviderAdvancedSearch').show();
    $('#ProviderAdvancedSearch fieldset').hide();
    $('#ProviderAdvancedSearch div.ArrowImage img').removeClass('active');
}

function changeDropdownBasedOnReferrer() {
	var referrer = document.referrer;
	if (referrer.toLowerCase().indexOf("butterflies") > -1) {
		//set a cookie to restrict the user on what options the see when coming from the /butterflies/
		$.cookie('Referrer', 'Butterflies');
		//call the change event so both dropdowns will appear and we can change values on both.
		//Now the page will reload and we can pick up in the 'Else if' block because of the cookie
		$('div.Normal.Subsidiary option[value= "Home Care"]').attr('selected', true).change();
	} else if ($.cookie('Referrer') == 'Butterflies') {
		//if a new tab is opened we call the change event so the fund dropdown will display, the page will reload and the options will be built in
		if($('div.Normal [id*="CareFund"] select').length === 0) {
			$('div.Normal.Subsidiary option[value= "Home Care"]').attr('selected', true).change();
		}

		//make "Home Care" the only option for the subsidiary dropdown
		$('div.Normal.Subsidiary select').html('<option value="Home Care" selected="selected">Home Care</option>');
		//make "Butterflies in the Park" the only option for the fund dropdown
		$('div.Normal [id*="CareFund"] select').html('<option value="Butterflies in the Park" selected="selected">Butterflies in the Park</option>');

		//if the page is refreshed, the cookie remains intact, but if the browser is shut down, the user is no loger locked down with the options
		$(window).unload(function() {
		   $.cookies.del('Referrer');
		});
	}
}

function requireParticipatingPlan(){
    
    var fieldset = '#ProviderAdvancedSearch fieldset',
        nonClass = 'non-plan-inputs',
        facet = '5fc03462d2404988886ea17d1b7d341e',
        element = $('.' + facet),
        menu = $(fieldset + ' #' + facet + '_ddl'),
        checkValue = '00000000-0000-0000-0000-000000000000';
    
        //wrap all inputs
        $(fieldset).wrapInner('<div class="'+ nonClass + '" style="float: left; display: none;"/>');
    
        //move plan element to top of fieldset
        $(fieldset).find('.' + facet).prependTo(fieldset);
        
        //change text
        $(fieldset).find('.' + facet + ' option:first-child').text('Select Participating Plan');
        
        //unhide non-plan-inputs when plan is selected
        menu.change(function() {
            if (menu.val() != checkValue) {
                $(fieldset).find('.' + nonClass).show();
            }else{
                $(fieldset).find('.' + nonClass).hide();
            }
        });
        
    var getTermId = getUrlParameter('termId');
    if (getTermId !== checkValue && getTermId !== undefined && menu.val() != checkValue){
        $(fieldset).find('.' + nonClass).show();
    } else {
        $(fieldset).find('.' + nonClass).hide();
    }

    function getUrlParameter(sParam) {
        var sPageURL = decodeURIComponent(window.location.search.substring(1)),
            sURLVariables = sPageURL.split('&'),
            sParameterName,
            i;

        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');

            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined ? true : sParameterName[1];
            }
        }
    }
    
}

function changeNewSearchLink(elem, changeTo) {
    
    var link = $(elem);
    
    //update New Search link on lcoation profile page
    if (link.length > 0) {
        link.attr('href', changeTo);
    }
}



$(window).load(function () {
	if ($('#DrResults').length) {
		mobileProviderSearch();
		desktopProviderSearch();
	}
	changeDropdownBasedOnReferrer();

    requireParticipatingPlan();

    changeNewSearchLink('#LocationsDetail .ReturnToResults a', '/facility/');
        

    clickToCall.clickToCall();

    
});
$(window).resize(function () {
//	console.log($('body').hasClass('Desktop'));
	if ($('body').hasClass('Mobile') || $('body').hasClass('MobileSmall')) {
		if ($('#ProximityNav #ProviderAdvancedSearch.DrResultsPage').length) {
			mobileProviderSearch();
		}

	} else if ($('body').hasClass('Desktop') || $('body').hasClass('Tablet')) {
		if ($('#PageContent #DrResults #ProviderAdvancedSearch').length) {
			desktopProviderSearch();
		}
	}
    
    clickToCall.clickToCall();
});

function providerVideo() {
	$('a.ProviderVideo').fancybox({
		'transitionIn': 'elastic',
		'transitionOut': 'elastic',
		'autoscale': true,
		'overlayColor': '#1f1f1f',
		'titleShow': true,
		'titlePosition': 'outside',
		'type': 'iframe'
	});
}

function getParameterByName(name) {
	name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
	var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
		results = regex.exec(location.search);
	return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}

var clickToCall = (function($) {
    var clickToCall = function() {
        /*
        This is a global click to call function. This will match phone numbers in these formats and convert them to anchors with 'tel' attributes:

        123 456 7890
        (123)456 7890
        (123) 456 7890
        (123)456-7890
        (123) 456-7890
        123.456.7890
        123-456-7890
        *This also accounts for the '1-800' scenario with a space, dot or dash separating the '1' and '800'

        Example: <a href="tel:1234567890">(123) 456-7890</a>
        */

        var phoneRegEx = new RegExp(/(1[\s.-])?([(]?\d{3}[)]?[(\s)?.-]\d{3}[\s.-]\d{4})(?![^<]*>|[^<>]*<\/)/g);

        /*
        RegEx Explained

        /           Start the expression
        (1[\s.-])?  An optional '1' followed by an optional space, dot or dash (to be used in '1-800')
        [(]?        Optional left parenthesis
        \d{3}\      Look for 3 digits (area code)
        [)]?        Optional right parenthsis
        [(\s)?.-]   Optional space (after the area code) or a dot or dash
        \d{3}       Look for 3 digits (the first part of the phone num)
        [\s.-]      Either a space, dot, or dash to separate the first 3 numbers of the phone number
        \d{4}       The last 4 digits of the phone num
        (?![^<]*>|[^<>]*<\/)  This is a negative lookup. Make sure the number is not directly nested in any tag. Ex: a tag with an ID or class that has digits in this format
        /g          Global search

        */

        //search through the immediate children of the body and construct a new jQuery object from the matching elements.
        $("body *:not(iframe)").contents().each(function() {
            //return if nodeType isn't a text node (Text Node: Represents textual content in an element or attribute. No children.)
            if (this.nodeType != 3) {
                return;
            }
            //we don't want to change phone numbers that are already in a link
            if ($(this).closest('a').length) {
                return;
            }
            //test against the regEx
            var match = $(this).text().match(phoneRegEx);
            if (match === null || match.length === 0) {
                return;
            }
            var newhtml = $(this).text().replace(phoneRegEx, function(match) {
                //remove spaces, dashes, dots and parenthesis for the href
                var phonenumber = match.replace(/ /g, "").replace(/-/g, "").replace(/\./g, "").replace(/\(/g, "").replace(/\)/g, "");
                
                
                var link = '';
                
                if ($('body').hasClass('Mobile') || $('body').hasClass('MobileSmall')) {
                    
                    link = '<a href="tel:' + phonenumber + '">' + match.replace(/(\d{3})-(\d{3})-(\d{4})/, '($1) $2-$3') + '</a>';
                } else {
                    link = match.replace(/(\d{3})-(\d{3})-(\d{4})/, '($1) $2-$3');
                }
                
                return link;
            });
            
            $(this).replaceWith(newhtml);
        });
    }

    /*
    This function is used for the 'click to call'. This can be used for all mobile devices.
    IOS automatically converts phone numbers, but does not recognize all phone formats (EX: 555-555-5555)
    so applying this for IOS will ensure all phone number get converted. This plugin will not break the default number conversion by IOS.
    Here is an example for calling this on IOS and Android devices:
    */

    var isAppleDevice = function() {
        "use strict";
        if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
            return true;
        } else {
            return false;
        }
    }

    var isAndroidDevice = function() {
        "use strict";
        if (/Android/i.test(navigator.userAgent)) {
            return true;
        } else {
            return false;
        }
    }

    /*
    If you are using infinite scrolling where new phone numbers may be added after the script has run, or other dynamic phone numbers that are populated via script after page load, you’ll need to make sure to add those as well.
    I added this functionality for the infinite scrolling for providers and locations for Milford just so we don’t have to scan the entire page again when new entries are added:
    */

    function infiniteScrollClickToCall() { //use this for infinite scrolling instead of looking through the entire site each time
        $('.DrListContainer ul li span.Phone, .LocationsList ul li span.Phone').each(function() { //only look for phone numbers that have a class of phone (this should be all of them)
            if ($(this).find('a').length === 0) { //disregard phone numbers that are already in an anchor
                var regex = /([(]?\d{3}[)]?)[(\s)?.-](\d{3})[\s.-](\d{4})/g; // Adding phone # links around phone numbers
                var text = $(this).html();
                text = text.replace(regex, "<a href='tel:$1$2$3'>$&</a>");
                $(this).html(text);
            }
        });
    }

    return {
        isAppleDevice: isAppleDevice,
        isAndroidDevice: isAndroidDevice,
        infiniteScrollClickToCall: infiniteScrollClickToCall,
        clickToCall: clickToCall
    }
})(jQuery);